export default {
  overview: {
    title: 'Übersicht',
    account_details: 'Konto',
    current_users: 'Nutzer:innen',
    upgrade_account: 'Account upgraden',
    cancel_subscription: 'Abonnement beenden',
    details: {
      account_type: 'Kontotyp',
      trial: 'Probemonat',
      days_left: 'Endet in',
      start_date: 'Abrechnungsbeginn',
      next_date: 'Nächster Zahlungstermin',
    },
    users: {
      learners: 'Teammitglieder',
      admins: 'Admins',
      owner: 'Owner',

      pending_invites: 'Offene Einladungen',
    },

    enterprise: {
      learners: 'Lerner:innen',
      admins: 'Administrator:innen',
      owner: 'Eigentümer:in',
    },

    you: '(Sie)',
  },

  madal_subscription_cancel: {
    header: 'Hallo {user}',
    ask_feedback:
      'Wir bedauern sehr, Sie als Kund:in zu verlieren. Würden Sie uns den wesentlichen Grund für Ihre Entscheidung nennen, damit wir unser Produkt und unseren Service verbessern können?',
    thank:
      'Wir wissen Ihr Feedback sehr zu schätzen und danken Ihnen für Ihre Unterstützung.',
    team: 'Mit freundlichen Grüßen, <br /> Ihr wîse up Team',

    btn_cancel: 'Abbrechen',
    btn_submit: 'Bestätigen',
  },

  payment_methods: {
    title: 'Zahlungsmethoden',
    table: {
      no_methods: 'Keine Methoden',
      expires_on: 'Gültig bis',
      remove: 'Entfernen',
      edit: 'Bearbeiten',
    },
    add_new: 'Neue Karte hinzufügen',
    end_trial: 'Zahlungsdaten hinzufügen und Testphase beenden',
    enterprise: {
      paragraph:
        'Für Ihr Konto ist die Bezahlungsmethode "Rechnung" aktiviert.',
      contact_info: 'Rechnungsinformationen',
    },
    enterprise_form: {
      org_name: 'Name der Organisation',
      contact_name: 'Firmenname',
      phone: 'Telefonnummer',
      invoicing_mail: 'E-Mail-Adresse',
      add_new_email: 'Add additional e-mail',
      add_new_email_2: 'Rechnungs-E-Mail hinzufügen',
      address: 'Straße und Hausnummer',
      town: 'Ort',
      zip_code: 'Postleitzahl',
      skip_owner_invoice: 'Rechnungen nur an diese E-Mail-Adresse(n) senden?',
    },
    delete: {
      header: 'Zahlungsmethode entfernen',
      paragraph:
        'Sind Sie sich Sicher, dass Sie diese Zahlungsmethode entfernen möchten?',
      btn_cancel: 'Abbrechen',
      btn_submit: 'Bestätigen',
    },
  },

  invoices_history: {
    title: 'Rechnungen',
    table: {
      empty: 'Es gibt noch keine Transaktionen',
      payment_method: 'Zahlungsmethode',
      date: 'Datum',
      amount: 'Betrag',
      status: 'Status',
      stripe_id: 'Stripe ID',
      invoice_file: 'Rechnung',
    },
  },

  invoices_history_pro: {
    id: 'ID',
    status: 'Status',
    date: 'Erstellt am',
    number_license: 'Anzahl Lizenzen',
    price_license: 'Lizenzpreis',
    sum: 'Betrag',
    file: 'Download',
  },
}

export default {
  title: {
    header: 'Nur 14,90 € monatlich',
    subheader: 'Lizenz Wählen',
    text: 'Preis inkl. Steuern, Zahlung jährlich im Voraus. Probemonat ohne Vertragsbindung für bis zu 5 Nutzer (bei Business Accounts) und nach Bedarf (bei Business Pro Accounts). Keine Kosten bei Kündigung innerhalb des Probemonats.',
  },
  private: {
    header: 'Privat',
    desc: 'Erstellen Sie ein Lernkonto für sich selbst und starten Sie jetzt Ihren Probemonat.',
    btn_text: 'Jetzt starten',
    // footer: 'Billed annually',
  },
  business: {
    header: 'Business',
    desc: 'Erstellen Sie ein Lernkonto für sich und Ihr Team. Nutzen Sie wîse up, um gemeinsam zu lernen!',
    btn_text: 'Jetzt starten',

    // footer: 'Billed annually',
  },
  enterprise: {
    header: 'Business Pro',
    desc: 'Wir bieten attraktive Konditionen für Großunternehmen. Sprechen Sie noch heute mit unserem Vertrieb!',
    btn_text: 'Jetzt anfragen',
  },
  modal_header: 'Konto upgraden',
  its_your_type: 'Ihr aktuelles Modell',
  ends: 'Probemonat bis',
}

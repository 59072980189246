export default function getHttpErrorMessage(error) {
  let text = 'Something went wrong!'
  // let message = this.$t('toasts.went_wrong')

  if (error.response) {
    text = error.response.statusText

    if (typeof error.response.data !== 'string') {
      if (error.response.data.message) {
        text = error.response.data.message
      } else if (error.response.data.error) {
        if (typeof error.response.data.error === 'string') {
          text = error.response.data.error
        } else if (error.response.data.error?.detail) {
          text = error.response.data.error.detail
        }
      } else if (error.response.data.errors) {
        if (typeof error.response.data.errors === 'string') {
          text = error.response.data.errors
        } else {
          text = error.response.data.errors
            .map((err) => {
              return err.detail || err.title
            })
            .join(' ')
        }
      } else {
        try {
          text = Object.keys(error.response.data)
            .map((filed) => {
              return Object.keys(error.response.data[filed]).map((e) => {
                return error.response.data[filed][e]
              })
            })
            .join()
        } catch (e) {
          console.dir(e)
          text = error.response.statusText
        }
      }
    } else if (error.response.status == 404) {
      text = 'Not Found'
    } else if (error.response.status == 403) {
      text = 'Forbidden: Access is denied'
    } else if (error.response.status == 406) {
      text = 'Access is denied'
    }
  } else if (error.request) {
    console.dir(error)

    text = error.message || 'Request failed'
  } else {
    text = error.message
  }

  return text
}

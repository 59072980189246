import axios from 'axios'

const axiosApi = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/api/v1/`,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
})

export default axiosApi

import OnboardingRoutes from './routes/onboarding.routes.js'
import ProfileRoutes from './routes/profile.routes.js'
import LoginRoutes from './routes/login.routes.js'

const NotFound = () => import('@/pages/NotFound/index.vue')

export default [
  ...OnboardingRoutes,
  ...ProfileRoutes,
  ...LoginRoutes,
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
]

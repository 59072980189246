import { createI18n } from 'vue-i18n'
// import en from './en/common.js'
import de from './de/common.js'

const i18n = createI18n({
  // legacy: false,
  locale: 'de',
  messages: {
    // en,
    de,
  },
})

export default i18n

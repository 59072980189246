export default {
  header: {
    title: 'Teammitglieder einladen',
    subtitle: 'Schritt 2/3',
    trial: '30 Tage kostenlos testen',
    trial_continue: 'für bis zu 5 Nutzer:innen',
    text: 'Achtung: Das Probemonat gilt nur für bis zu 5 Nutzer. Bei mehr als 5 Nutzern beginnt die Vertragsbindung (14,90 € pro Monat, Zahlung jährlich im Voraus) sofort.',
  },

  form: {
    email: 'E-Mail-Adresse',
    email_placeholder: 'E-Mail-Adressen eingeben oder einfügen',
    you: '(das sind Sie)',
    exists: 'Diese E-Mail existiert bereits',
    continue: 'Weiter',
    invite: 'Einladung senden',
    invite_and_pay: 'Kostenpflichtig bestellen und Einladung senden',
    note: 'Weitere Personen können später jederzeit eingeladen werden.',
    btn_add: 'Hinzufügen',
    btn_import: 'CSV Import',
    btn_tooltip:
      'Erstellen Sie eine Tabelle mit dem Text "email" (ohne Anführungszeichen) in der Zelle A1 und den E-Mail Adressen Ihrer Mitarbeiter:innen in den Zellen der Spalte A darunter. Speichern Sie die Tabelle als CSV-Datei (kommagetrennte Werte).',
    trial_disable: `WICHTIG: Der Testzeitraum wird nur für bis zu 5 Nutzer:innen angeboten. Bei mehr als 5 Nutzer:innen beginnt die Vertragslaufzeit sofort (€14.90 monatlich, Zahlung jährlich im Voraus).`,
  },

  confirm: {
    title:
      'Sind Sie sicher, dass Sie selbst keinen Zugang zur Lernplattform benötigen?',
    text: 'Sie können sich selbst auch erst später hinzufügen.',
    cancel: 'Abbrechen',
    sure: 'Ja, mich entfernen',
  },
}

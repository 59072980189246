export default {
  required: 'Dieses Feld ist erforderlich.',
  minLength: 'Muss mindestens {min} Symbole lang sein',
  email: 'Das Feld muss wie eine E-Mail sein',
  sameAs: 'Passwörter müssen identisch sein',
  numeric: 'Muss eine Nummer sein',
  hasUppercase: '1 Großbuchstabe',
  hasLowercase: '1 Kleinbuchstabe',
  hasNumber: '1 Zahl',
  hasSpecialChar: '1 Sonderzeichen',
  noKeyboardPatterns:
    'Keine Muster aus benachbarten Tasten der Tastatur (z.B. qwertz, asdf, fdsa)',
  noSequentialLettersOrNumbers:
    'Keine Reihen an gleichen Zeichen und keine auf- oder absteigenden Zahlenfolgen (z.B. abcd, zxyw, 1234, 9876)',
  password_tooltip:
    'Bitte verwenden Sie keine persönliche Informationen (z.B. Name, Geburtsdatum) und keine Begriffe aus dem Wörterbuch.',
  noFourIdenticalInARow:
    'Das Passwort darf keine Folge von 4 oder mehr identischen Zeichen enthalten (z. B. aaaa, zZZz).',
}

import axiosApi from '@/plugins/api.js'

import getHttpErrorMessage from '@/utils/http-error-message.js'

const state = {
  industry_areas: [],
  status: 'initial',
}

const mutations = {
  setIndustryAreas(state, areas) {
    state.industry_areas = areas
    state.status = 'loaded'
  },
}

const actions = {
  fetchIndustryAreas({ commit, state: { status } }) {
    if (status == 'loaded') return

    axiosApi
      .get('/industry_areas')
      .then(({ data }) => {
        commit('setIndustryAreas', data)
      })
      .catch((error) => {
        const message = getHttpErrorMessage(error)
        this.toast.error(message)
      })
  },
}

const getters = {
  industryAreas: (state) => state.industry_areas,
}

export const industry_areas = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default {
  oranization_name:
    'Ihr Firmenname erscheint auf Rechnungen und wird auch innerhalb der Lernplattform verwendet, z.B. als Gruppenname.',
  wko_ID: `Die 7-stellige WKO Mitgliedsnummer wird dem Mitglied 
    mit dem Startpaket der WKO bzw. bei Umgründung und Standortverlegung per Brief zugesendet.
    Sollte die WKO Mitgliedsnummer nur 6-stellig sein, geben Sie das Bundesland des 
    Unternehmens an erster Stelle ein: 0 für Wien, 1 für Niederösterreich, 3 für Oberösterreich,
    4 für Salzburg, 5 für Steiermark, 6 für Kärnten, 7 für Tirol, 8 für Vorarlberg oder 
    9 für das Burgenland.`,
}

import { createStore } from 'vuex'
import { account } from './modules/account.module'
import { industry_areas } from './modules/industry_areas.module'

export default createStore({
  modules: {
    account,
    industry_areas,
  },
})

<template>
  <Head>
    <html lang="de" />
  </Head>

  <component :is="layout" :data-page="pageCssClass">
    <router-view />
  </component>
</template>

<script>
import { Head } from '@vueuse/head'

import DefaultLayout from '@/layouts/DefaultLayout.vue'
import OnboardingLayout from '@/layouts/OnboardingLayout.vue'

export default {
  components: {
    DefaultLayout,
    OnboardingLayout,
    Head,
  },

  computed: {
    layout() {
      return this.$route.meta.layout || 'default-layout'
    },

    pageCssClass() {
      return this.$route.name
    },
  },
}
</script>

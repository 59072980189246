import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import { createRouter, createWebHistory } from 'vue-router'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import routes from './router/index.js'
import axiosApi from './plugins/api.js'
import i18n from './lang/main.js'

import Toast from 'vue-toastification'
import store from './store'
import vClickOutside from 'click-outside-vue3'

import './index.scss'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, _from, next) => {
  if (to.matched.some((record) => record.meta.unauthorized)) {
    next()
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters['account/isAuthenticated']) {
      next({ name: 'profile', replace: true })
    } else {
      next()
    }
  } else {
    if (store.getters['account/isAuthenticated']) {
      if (
        store.getters['account/isPaymentMethodNeeded'] &&
        to.name !== 'onboarding-billing'
      ) {
        next({ name: 'onboarding-billing', replace: true })
        next()
      } else if (
        store.getters['account/isGrunderServiceType'] &&
        store.getters['account/isMemberRole']
      ) {
        window.location.replace(import.meta.env.VITE_EDCAST_URL)
      } else if (
        store.getters['account/organizationInvitation'] &&
        to.name !== 'invitation' &&
        store.getters['account/organizationRole'] === 'admin'
      ) {
        next({ name: 'invitation', replace: true })
      } else if (
        store.getters['account/isUpdatePasswordNeeded'] &&
        to.name !== 'update-pass-page'
      ) {
        next({ name: 'update-pass-page', replace: true })
      } else {
        next()
      }
    } else {
      next({ name: 'sign-in-page', replace: true })
    }
  }
})

/**
 * Returns initialization of app
 * @returns {object} Return current user data
 */
async function init() {
  try {
    const {
      data: { user },
    } = await axiosApi.get('/users/me')

    if (user) {
      store.commit('account/loginSuccess', user)
    }
  } catch (e) {
    // console.log(e)
  }

  const app = createApp(App)
  app.use(i18n)
  app.use(router)
  app.use(Toast, {
    timeout: 5000,
    draggable: true,
    icon: false,
    hideProgressBar: true,
    closeButton: false,
  })

  app.use(store)
  app.use(vClickOutside)
  app.config.globalProperties.$api = axiosApi

  const head = createHead()
  app.use(head)

  Sentry.init({
    app,
    debug: import.meta.env.DEV,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    dsn: import.meta.env.VITE_SENTRY_DNS,

    integrations: [
      // new Sentry.BrowserTracing({
      //   routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      // }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 0.1,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.5,
  })

  if (
    store.getters['account/trialHasEnded'] &&
    store.getters['account/trialNotInterrupted']
  ) {
    router.push({ name: 'trial-info' })
  }

  app.mount('#app')
}

init()

export default {
  form: {
    profile_settings: 'Profileinstellungen',
    organization_status: 'Meine Organisation',
  },

  account_info: {
    title: 'Übersicht',
    member_of_organization: 'Sie sind Mitglied bei',
    role_in_organization: 'Rolle:',
    transfer_ownership: 'Account-Inhaberschaft übertragen',
    learner: 'Owner:',
  },

  my_license: {
    title: 'Meine Lizenz',
    table: {
      license_id: 'Lizenz ID',
      status: 'Status',
      start_date: 'Startdatum',
      expiration_date: 'Gültig bis',
      no_licenses: 'Keine Lizenzen',
    },
    info: {
      text: 'Ihre jährliche Lizenz verlängert sich automatisch.',
      button: 'Zahlungsinfo überprüfen',
    },
    learner:
      'Ihre Lizenz wird von Ihrer Organisation <b>{org}</b> bereitgestellt.',
  },

  account_delete: {
    title: 'Account löschen',
    info: {
      text: 'Dieser Vorgang kann nicht rückgängig gemacht werden. Sind Sie sicher?',
      action: 'Diesen Account löschen',
    },

    caption: {
      text: 'Anstatt Ihren Account sofort zu löschen, können Sie unter {link} die automatische Verlängerung deaktivieren. Damit kann die aktuelle Lizenz für den verbleibenden Abrechnungszeitraum (bis 20. Mai 2022) weiterhin genutzt werden.',
      link: 'Zahlung',
    },

    confirm: {
      title: 'Sind Sie sicher, dass Sie Ihren Account löschen möchten? ',
      text: 'Dieser Vorgang kann nicht rückgängig gemacht werden. Sie verlieren den Zugriff auf die Lernplattform.',
      cancel: 'Abbrechen',
      delete: 'Löschen',
    },
  },
}

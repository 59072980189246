export default {
  header: {
    menu: {
      team: 'Team',
      billing: 'Zahlung',
      my_profile: 'Mein Profil',
      go_lpx: 'Zur Lernplattform',
    },
  },

  sidebar: {
    log_out: 'Abmelden',

    team: {
      title: 'Team',
      menu: {
        organization_info: 'Organisation',
        people: 'Nutzer:innen',
        invite: '+ Einladen',
        delete_organization: 'Organisation löschen',
      },
    },

    my_profile: {
      title: 'Mein Profil',
      menu: {
        account_info: 'Übersicht',
        password: 'Passwort',
        license: 'Lizenz',
        delete_account: 'Account löschen',
      },
    },

    billing: {
      title: 'Zahlung',
      menu: {
        overview: 'Übersicht',
        payment_methods: 'Zahlungsinfo',
        invoices_history: 'Rechnungen',
      },
    },
  },

  modalTrO: {
    header: 'Sie sind dabei, die Inhaberschaft Ihres Kontos zu übertragen',
    info: 'Bitte wählen Sie den neuen Account-Inhaber (Eigentümer:in)',
    placeholder: 'Teammitglied auswählen',
    btn_cancel: 'Abbrechen',
    btn_submit: 'Übertragen',
    meneger_asign: 'Führungskraft auswählen',
  },

  trial: {
    header: 'Ihr Testzeitraum ist abgelaufen',
    text: 'Sie können wîse up natürlich gerne weiterhin nutzen und Ihren Lernerfolg steuern. Geben Sie hierzu einfach Ihre Zahlungsdaten ein und schon geht Ihr Lernerlebnis weiter.',
    btn_activate: 'Zahlungsdaten hinzufügen',
  },
}

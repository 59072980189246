export default {
  title: {
    header: 'Login',
  },

  form: {
    email: 'E-mail',
    password: 'Passwort',
    btn_submit: 'Einloggen',
  },

  links: {
    forgot_pass: 'Ich habe mein Passwort vergessen',
    sign_up: 'Jetzt erstellen!',
    sign_up_text: 'Noch keinen Account?',
    marketing_site: {
      main_page: 'wise-up.at',
      faq: 'FAQs',
      agb: 'AGBs',
      legal_info: 'Impressum',
      data_use: 'Datenschutz',
      contact: 'Kontakt',
    },
  },
}

export default {
  title: {
    header: 'Passwort zurücksetzen',
    text: 'E-Mail-Adresse zur Kontowiederherstellung eingeben',
  },

  form: {
    email: 'E-mail',
    submit_btn: 'Neues Passwort anfordern',
    submit_notify:
      'Sofern ein entsprechendes Konto existiert, haben wir eine E-Mail mit einem Zugangslink gesendet.',
    submit_notify_btn: 'Zurück zur Startseite',
  },
}

export default {
  title: {
    header: 'Vertrieb kontaktieren',
  },

  form: {
    org_name: 'Firmenname',
    email: 'E-Mail-Adresse',
    org_address: 'Adresse',
    org_indastry_area: 'Branche',
    org_size: 'Betriebsgröße',
  },

  btn: {
    submit: 'Absenden',
  },

  confirm: {
    header: 'Vielen Dank für Ihre Anfrage!',
    text: 'Wir haben Ihre Anfrage erhalten und werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.',
    btn_back: 'Zurück zur Startseite',
    btn_close: 'Schließen',
  },
}

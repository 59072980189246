export default {
  title: {
    header: 'Willkommen bei wîse up',
    text: 'Erstellen Sie einen Account für sich selbst. Weitere Personen können (bei Business und Business Pro Accounts) später jederzeit eingeladen werden.',
    header_private: 'Willkommen zu wîse up',
    subheader: 'Registrierung',
    text_private:
      'Erstellen Sie Ihr persönliches Konto und starten Sie Ihr Lernerlebnis',
    text_business:
      'Erstellen Sie zu Beginn Ihr persönliches Konto. Weitere Personen können später jederzeit zu Ihrem Unternehmen eingeladen werden.',
  },
  form: {
    first_name: 'Vorname',
    last_name: 'Nachname',
    email: 'E-Mail-Adresse',
    password: 'Passwort',
    password_notification: 'Passwort muss mindestens 8 Zeichen lang sein',
    btn_continue: 'Jetzt 30 Tage kostenlos testen',
    btn_private_continue: 'Weiter',
    log_in: 'Einloggen',
    log_in_text: 'Sie haben bereits ein Konto?',
    // privacy_policy: 'Privacy Policy',
    // terms_of_service: 'Terms of Service',
    // agree: 'Do you agree to our  and ?',
  },
  invitedUser: {
    invited_by: 'Sie wurden eingeladen von',
    owner: 'Owner:',
    private_email: 'Private E-Mail-Adresse',
    optional: 'optional',
    title_tooltip:
      'Diese E-Mail-Adresse wird nur zur Kontowiederherstellung genutzt (keine Benachrichtigung, keine Marketing-Emails).',
  },
}

import Store from '@/store/index.js'

const Layout = () => import('@/pages/Onboarding/Layout/index.vue')
const SignUp = () => import('@/pages/Onboarding/SignUp/index.vue')

const ConfirmationInfo = () =>
  import('@/pages/Onboarding/ConfirmationInfo/index.vue')
const ConfirmationEmail = () =>
  import('@/pages/Onboarding/ConfirmationEmail/index.vue')
const PricingPage = () => import('@/pages/Onboarding/PricingPage/index.vue')
const RequestSalesPage = () =>
  import('@/pages/Onboarding/RequestSalesPage/index.vue')
const CreateOrganization = () =>
  import('@/pages/Onboarding/CreateOrganization/index.vue')
const Invites = () => import('@/pages/Onboarding/Invites/index.vue')
const Billing = () => import('@/pages/Onboarding/Billing/index.vue')
const PaymentComplete = () =>
  import('@/pages/Onboarding/PaymentComplete/index.vue')
const ConfirmationPay = () =>
  import('@/pages/Onboarding/ConfirmationPay/index.vue')
const TrialInfo = () => import('@/pages/Onboarding/TrialInfo/index.vue')
const InvitationPage = () =>
  import('@/pages/Onboarding/InvitationPage/index.vue')

export default [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'profile-account-info' },
  },

  {
    path: '/new',
    component: Layout,
    meta: {
      layout: 'onboarding-layout',
    },

    children: [
      {
        path: '',
        name: 'onboarding',
        redirect: { name: 'onboarding-signup' },
      },

      {
        path: 'register',
        name: 'onboarding-signup',
        component: SignUp,
        meta: { unauthorized: true },
      },

      {
        path: 'confirm-info',
        name: 'confirm-info',
        component: ConfirmationInfo,
        meta: { guest: true },
      },

      {
        path: 'confirm-pay',
        name: 'confirm-pay',
        component: ConfirmationPay,
        meta: { unauthorized: true },
      },

      {
        path: 'confirm-email',
        name: 'confirm-email',
        component: ConfirmationEmail,
        meta: { guest: true },
      },

      {
        path: 'select-package',
        name: 'pricing',
        component: PricingPage,
      },

      {
        path: 'request-sales',
        name: 'request-sales',
        component: RequestSalesPage,
        meta: { unauthorized: true },
      },

      {
        path: 'trial',
        name: 'trial-info',
        component: TrialInfo,
      },

      {
        path: 'create-organization',
        name: 'create-organization',
        component: CreateOrganization,

        beforeEnter: (to, from, next) => {
          if (Store.getters['account/hasOrganization']) {
            next({ name: 'onboarding-invites', replace: true })
          } else {
            next()
          }
        },
      },

      {
        path: 'invite-learners',
        name: 'onboarding-invites',
        component: Invites,

        beforeEnter: (to, from, next) => {
          if (Store.getters['account/hasOrganization']) {
            next()
          } else {
            next({ name: 'pricing', replace: true })
          }
        },
      },

      {
        path: 'payment',
        name: 'onboarding-billing',
        component: Billing,

        beforeEnter: (to, from, next) => {
          if (Store.getters['account/isBillingAvailable']) {
            next()
          } else {
            next({ name: 'pricing', replace: true })
          }
        },
      },

      {
        path: 'invitation-page',
        name: 'invitation',
        component: InvitationPage,

        beforeEnter: (to, from, next) => {
          if (Store.getters['account/organizationInvitation']) {
            next()
          } else {
            next({ path: '/profile', replace: true })
          }
        },
      },

      {
        path: 'payment-complete',
        name: 'onboarding-payment-complete',
        component: PaymentComplete,
        props: (route) => ({
          redirectStatus: route.query.redirect_status,
          setupIntent: route.query.setup_intent,
          setupIntentClientSecret: route.query.setup_intent_client_secret,
        }),
      },
    ],
  },
]

export default {
  owner: 'Eigentümer:in',
  admin: 'Administrator:in',
  member: 'Lerner:in',
  member_readonly: 'Lerner:in light',
  role_info: {
    owner: {
      text: 'Als Eigentümer haben Sie volle Kontrolle über diese Organisation. {0}',
      link: 'Eigentümerschaft übertagen',
      pro: 'Der/Die Account Inhaber:in kann dieses Organisationskonto verwalten.',
    },

    learner:
      'Nur der Account-Inhaber kann dieses Organisationskonto verwalten.',
  },
}

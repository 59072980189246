// onboarding
import pricing from './onboarding/pricing'
import create_org from './onboarding/create_org'
import agreement from './onboarding/agreement'
import tooltips_text from './onboarding/tooltips_text'
import sign_up from './onboarding/sign_up'
import confirm_email from './onboarding/confirm_email'
import request_sales from './onboarding/request_sales'
import invites from './onboarding/invites'
import billing from './onboarding/billing'
import gruender_service from './onboarding/gruender_service'
// profile
import profile from './profile/profile'
import my_profile from './profile/my_profile'
import mybilling from './profile/billing'
import team from './profile/team'
// signin
import signin from './signin/signin'
import reset_pass from './signin/reset_pass'
import create_pass from './signin/create_pass'
// validation
import validations from './validations'
// errors
import toasts from './toasts'
//statuses
import statuses from './statuses'
//roles
import role from './role'

export default {
  pricing,
  create_org,
  agreement,
  sign_up,
  confirm_email,
  tooltips_text,
  invites,
  billing,
  request_sales,
  gruender_service,

  mybilling,
  profile,
  my_profile,
  team,

  signin,
  reset_pass,
  create_pass,

  validations,
  toasts,

  statuses,

  role,

  buttons: {
    submit: 'Bestätigen',
    cancel: 'Abbrechen',
    upgrade: 'Upgrade',
    probemonat: 'Probemonat beenden',
  },

  day: 'tag | tagen',
}

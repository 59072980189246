export default {
  payment_success: 'Zahlungsmethode erfolgreich hinzugefügt.',
  payment_delete: 'Zahlungskarte wurde gelöscht',
  confirmation_success: 'Ihre E-Mail-Adresse wurde erfolgreich bestätigt!',
  email_instruction:
    'Wir haben die E-Mail mit weiteren Details erneut versendet.',
  org_creation_success: 'Organisation aktualisiert!',
  org_update_success: 'Organisation wurde aktualisiert!',
  invite_success: 'Einladungen wurden versendet!',
  thx_success: 'Vielen Dank!',
  wellcome: 'Willkommen!',
  account_success: 'Kontoinformationen wurden aktualisiert!',
  password_success: 'Passwort wurde erfolgreich aktualisiert!',
  password_create_success: 'Passwort erstellt!',
  member_deleted_success: 'Das Teammitglied wurde erfolgreich gelöscht!',
  member_updated_success: 'Das Teammitglied wurde erfolgreich aktualisiert!',
  email_success: 'E-Mail gesendet!',
  invite_resend_successfully: 'Einladung erfolgreich erneut senden!',

  went_wrong: 'Da ist etwas schief gegangen.',

  assign_manager: 'ist jetzt Manager von',
  delete_meneger: 'Manager wurde entfernt',

  re_assign:
    'Sind Sie sicher, dass Sie die Führungskraft für die Nutzer:innen ändern wollen?',

  toast_success_owner: ' ist jetzt Besitzer einer ',
  toast_success_subsc_cancel: 'Das Abonnement wird gekündigt',

  license_success: 'Zusätzliche Lizenzen hinzugefügt',
}

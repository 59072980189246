<template>
  <div class="app-layout">
    <!--
    ******************************************************************************
    ******************************************************************************
      BODY
    ******************************************************************************
    ******************************************************************************
    -->
    <main>
      <slot />
    </main>
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout',
}
</script>

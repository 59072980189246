const SignInLayout = () => import('@/pages/SignIn/Layout/index.vue')
const SignInPage = () => import('@/pages/SignIn/SignInPage/index.vue')
const CreatePassPage = () => import('@/pages/SignIn/CreatePassPage/index.vue')
const RestorePassPage = () => import('@/pages/SignIn/RestorePassPage/index.vue')

export default [
  {
    path: '/login',
    component: SignInLayout,

    meta: { guest: true },

    children: [
      {
        path: '',
        name: 'sign-in-page',
        component: SignInPage,
      },

      {
        path: 'restore-pass',
        name: 'restore-pass-page',
        component: RestorePassPage,
      },

      {
        path: 'create-pass',
        name: 'create-pass-page',
        component: CreatePassPage,
      },
    ],
  },
]
